import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import router from './router'
import store from './store'
import axios from 'axios';
import BootstrapVue3 from 'bootstrap-vue-3'
import VueCookies from 'vue-cookies'


import VueGtag from "vue-gtag";

import vue3GoogleLogin from 'vue3-google-login'

//======== CSS things

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/style.scss'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faArrowLeft, 
    faWallet,
    faHome,
    faUser,
    faBell,
    faNewspaper,
    faSearch,
    faXmark,
    faCopy,
    faEnvelope,
    faPowerOff,
    faArrowRight,
    faChartPie,
    faBookmark
  } from '@fortawesome/free-solid-svg-icons'

import { faFacebook, faGoogle, faTelegram, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';

library.add(
    faArrowLeft, 
    faWallet,
    faHome,
    faUser,
    faBell,
    faNewspaper,
    faSearch,
    faXmark,
    faWhatsapp,
    faFacebook,
    faTelegram,
    faTwitter,
    faCopy,
    faEnvelope,
    faArrowLeft,
    faPowerOff,
    faArrowRight,
    faGoogle,
    faChartPie,
    faBookmark,
    farBookmark
    )

//======== CSS things
 
//Axios base URL
if(process.env.VUE_APP_FLAVOR === "ZEALS"){
    axios.defaults.baseURL = process.env.VUE_APP_API_ZEALS_URL;
}else if(process.env.VUE_APP_FLAVOR === "KAILS"){
    axios.defaults.baseURL = process.env.VUE_APP_API_KAILS_URL;
}

window.fbAsyncInit = function () {
  FB.init({
      appId: 7138877076124397,
      cookie: true,
      xfbml: true,
      version: 'v8.0'
  });
};

(function (d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) { return; }
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk')); 


//==============FIREBASE NOTIFICATION
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getToken,onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";
import { onBackgroundMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAqvrZstkV-QDr-jeJ6cnUwhFdzIk4TXmw",
  authDomain: "ivo-zeals-poc.firebaseapp.com",
  projectId: "ivo-zeals-poc",
  storageBucket: "ivo-zeals-poc.appspot.com",
  messagingSenderId: "485827581128",
  appId: "1:485827581128:web:66db7c3833217f27bd81fd",
  measurementId: "G-8BLBHJPCHX"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging();

getToken(messaging, { vapidKey: 'BMv5wIIBFVxVxPfKts6wcYu56RHz8fefX_QQ4GtMoNZ1IRxJOETbrfImaF5xoZ4f-xLdnWovHL8YpH_Ge-fJF_c' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
    $cookies.set("ZEALS_FIREBASE_TOKEN",currentToken);

    
    console.log(currentToken,"TK")
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);

//   const notificationTitle = 'Background Message Title';
//   const notificationOptions = {
//     body: 'Background Message body.',
//     icon: '/firebase-logo.png'
//   };

//   self.registration.showNotification(notificationTitle,notificationOptions);
// });

// onBackgroundMessage(messaging, (payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here
//   const notificationTitle = 'Background Message Title';
//   const notificationOptions = {
//     body: 'Background Message body.',
//     icon: '/firebase-logo.png'
//   };

//   self.registration.showNotification(notificationTitle,notificationOptions);
// });

//==============FIREBASE NOTIFICATION


createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(BootstrapVue3)
    .use(VueGtag, {
      config: { id: "G-0SVEZW47K8" }
    }, router)
    .use(store)
    .use(router)
    .use(VueCookies, { expires: '365d'})
    .use(store)
    .use(vue3GoogleLogin, {
      clientId: '485827581128-6tru18ug4jrd7ehfgdrqnvr17pj1mks0.apps.googleusercontent.com'
    })
    .mount('#app')
