//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const cors = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
      }
}

const state = {
    user: null,
    token: null
};

const getters = {
    isAuthenticated: function(){
        const account = $cookies.get("ZEALS_USER")
        console.log(account,"ACC")
        if(account!=null || account!=undefined){
            return true
        }else{
            return state.user
        }
    },    
    StateUser: function(){
        if(state.user){
            return state.user
        }else{
            return $cookies.get("ZEALS_USER")
        }
    },

    userToken: function(){
        if(state.token){
            return state.token
        }else{
            return $cookies.get("ZEALS_TOKEN")
        }
    },

    getPhone: function(){
        return state.phone
    },

    getUser: function(){

        if(state.user){
            return state.user
        }else{
            return $cookies.get("ZEALS_USER")
        }
    },
};

const actions = {
    checAuth({dispatch},url){
        const user = $cookies.get("ZEALS_TOKEN")
        if(!user && url.path!='/login'){
            // if(){
            MainApp.push("/login")
                return null
            // }
        }else{
            const fbt = $cookies.get('ZEALS_FIREBASE_TOKEN')
            return fbt
        }
    },

    async ConfirmOTP({dispatch}, form) {
        const response = await axios.post('confirm-otp', form)
        return response
    },

    async changePIN({dispatch}, form) {
        const response = await axios.post('change-pin', form)
        return response
    },

    async Login({commit}, form) {
        const response = await axios.post('login', {}, {
            auth: {
                username:form.email,
                password:form.password
            }
        })

        await commit('setUser', response.data.user)
        return response
    },

    setLogin({commit}, data){
    
        $cookies.set("ZEALS_TOKEN",data.token)
        $cookies.set("ZEALS_USER",data.user)

        console.log(data,"DATANYA")
        commit('setuserdata',data)
    },

    async Logout({dispatch}){
        let user = null
        $cookies.remove("ZEALS_TOKEN")
        $cookies.remove("ZEALS_USER")
        
        return true;
    },

    setUserPhone({commit}, phone){
        state.phone = phone
    }
};

const mutations = {
    setUser(state, datauser){
        state.user = datauser
    },
    setPosts(state, posts){
        state.posts = posts
    },
    logout(state){
        state.user      = null
        state.worker    = null
        state.token     = null
        state.doctor    = null
    },
    setuserdata(state,data){
        state.user      = data.user
        state.token     = data.token

        console.log(state,"STATES")
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};
