<template>
  <keep-alive>
    <RouterView></RouterView>
  </keep-alive>
</template>

<script>
</script>

<style>
</style>
