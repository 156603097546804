//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const cors = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
    }
}

const state = {
    list: null
};

const getters = {};

const actions = {

    async getCampaigns({dispatch}, form) {
        const headers = {
            Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }


        const response = await axios.get('campaign-list', {params: form, headers: headers})
        return response
    },

    async getDetailCampaign({dispatch}, form) {
        const headers = {
            Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }


        const response = await axios.get('campaign-detail-android', {params: form, headers: headers})
        return response
    },

    async joinCampaign({dispatch}, form) {
        const headers = {
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }
        const response = await axios.post('campaign-join', {}, {params: form, headers: headers})
        return response
    },

    async bookmarkCampaign({dispatch}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        }

        const response = await axios.post('campaign-bookmark', form, headers)

        return response
    },

    async deleteBookmark({dispatch}, form) {

        const headers = {
            Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }

        const response = await axios.get('campaign-removebookmark', {params: form, headers: headers})
        return response

        // const headers =  {
        //     Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        // }

        // const response = await axios.get('',{ params: form, headers: headers})

        // return response
    }


};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
