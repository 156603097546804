const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    startIntro({dispatch}, form) {
        const introJS = require("intro.js")();
        
        introJS.start();
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
