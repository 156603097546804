//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const cors = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
      }
}

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async getProfile({dispatch}, form) {
        const headers =  {
            Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }


        const response = await axios.get('profile',{ params: form, headers: headers})
        return response
    },

    async saveUpdate({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        }

        const response = await axios.post('profile-update',form, headers)
        return response
        
    },

    async LoginWithGoogleToken({dispatch}, form) {
        const response = await axios.post('g-login',form)
        return response
    },

    async Register({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        }

        const response = await axios.post('register',form, headers)
        return response
        
    },

    async ResetPassword({dispatch}, form) {
        const response = await axios.post('resetpassword', form)
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};